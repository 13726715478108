.admin-panel {
  padding: 30px 0;

  .admin-navigation {
    background-color: $purple;
    color: $white;
    padding: 20px;
    margin-bottom: 20px;

    .heading {
      color: $green;
      font-weight: $f-semi-bold;
      margin-bottom: 10px;
      font-size: 1.5rem;
      text-decoration: none;
      transition: all 0.3s ease;

      &:hover {
        opacity: 0.7;
      }
    }

    .navbar {
      padding: 0;
      margin: 0;

      .navbar-nav {
        .nav-item {
          .nav-link {
            padding: 0 10px;
            color: $white;
            font-weight: $f-light;
            font-size: 1.125rem;
            transition: all 0.3s ease;

            i {
              color: rgba(255, 255, 255, 0.4);
              margin-right: 10px;
            }

            &:hover {
              opacity: 0.7;
            }
          }

          @media (min-width: 992px) {
            &:first-child {
              .nav-link {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }

  .nav-blocks {
    padding: 10px;
    margin-bottom: -30px;

    .nav-block {
      display: block;
      background-color: $white;
      text-align: center;
      padding: 30px;
      border-top: 7px solid $green;
      text-decoration: none;
      transition: all 0.3s ease;
      margin-bottom: 30px;

      @media (min-width: 768px) {
        padding: 50px;
      }

      i.fas {
        font-size: 5rem;
        color: rgba(0, 0, 0, 0.2);
        margin-bottom: 30px;
      }

      .header {
        .heading {
          margin-bottom: 0;
          font-size: 1.25rem;
        }
      }

      &:hover {
        transform: scale(1.03);
        box-shadow: 0 5px 40px rgba(0, 0, 0, 0.13);
      }
    }
  }

  .panel-content {
    .heading {
      font-weight: $f-semi-bold;
      color: $purple;
      font-size: 1.75rem;
      margin-bottom: 20px;
    }

    .content {
      background-color: $light;
      padding: 20px;

      table {
        width: 100%;
        font-size: 0.875rem;

        tr {
          th {
            text-transform: uppercase;
          }
        }
      }

      .meta-section {
        padding: 20px 0;
        border-bottom: 1px solid $green;

        h4 {
          font-size: 1.125rem;
          font-weight: $f-semi-bold;
          margin-bottom: 0;
        }

        p {
          margin-bottom: 0;
        }

        a {
          color: $purple;
        }

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border: none;
        }
      }

      .admin-section {
        margin-bottom: 30px;

        .section-info {
          .heading {
            font-size: 1.5rem;
            margin-bottom: 1rem;
          }

          p {
            font-weight: $f-light;
          }

          .subheading {
            font-size: 1rem;
            font-weight: $f-semi-bold;
          }
        }

        .section-content {
          margin-top: 10px;

          h4,
          .heading {
            font-size: 1rem;
            text-transform: uppercase;
          }

          .header {
            .heading {
              margin-bottom: 0.5rem;
            }

            p {
              font-weight: $f-light;
            }
          }

          .sub-section {
            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }

        .edit-link {
          background-color: transparent;
          padding: 0;
          border: none;
          color: $purple;
          text-decoration: underline;
        }
      }

      .footer {
        margin-top: 20px;
      }

      .image-details {
        .img-wrapper {
          position: relative;
          margin-bottom: 20px;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }

          .delete-icon {
            position: absolute;
            padding: 0;
            background-color: transparent;
            border: 0;
            top: 3px;
            right: 5px;
            transition: all 0.3s ease;

            .fa-circle {
              color: rgba(216, 24, 24, 0.7);
            }

            &:hover {
              opacity: 0.7;
            }
          }

          img {
            max-width: 100%;
          }
        }
      }

      .flat-details {
        .accordion {
          .card {
            border-radius: 0;
            margin-bottom: 10px;
            border: 1px solid rgba(0, 0, 0, 0.125);

            .card-header {
              background-color: $white;
              border-radius: 0;
              border-top: 7px solid $purple;
              padding: 0;

              button {
                text-align: left;
                width: 100%;
                padding: 20px;
                color: $purple;
                border: 0;
                font-size: 1.125rem;
                font-weight: $f-semi-bold;
                text-decoration: none;
              }
            }

            .card-body {
              .edit-flat {
                margin-bottom: 20px;
              }

              table {
                margin-bottom: 20px;

                tr {
                  &:nth-child(even) {
                    background-color: $light;
                  }
                }
                th,
                td {
                  padding: 5px;
                }
              }
            }
          }
        }

        .add-flat {
          margin-top: 10px;
        }
      }
    }
  }

  .tenancy-section {
    margin-bottom: 30px;

    .header {
      margin-bottom: 20px;

      .heading {
        font-size: 1.5rem;
      }
    }

    .room-card {
      background-color: $white;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .header {
        background-color: $green;
        padding: 10px;
        margin-bottom: 0;

        .heading {
          font-size: 1.125rem;
          font-weight: $f-semi-bold;
          color: $purple;
          margin-bottom: 0;
        }
      }

      .left-content {
        .featured-images {
          display: flex;

          .image-block {
            width: 100%;
            padding-bottom: 40%;
            background-size: cover;
            background-position: center center;

            &:first-child {
              margin-right: 1.5px;
            }
          }
        }

        .address {
          padding: 25px;

          h3 {
            font-size: 1.125rem;
            margin-bottom: 0;
            font-weight: $f-semi-bold;
            color: $purple;
          }
        }
      }

      .property-info {
        color: $purple;
        padding: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .address {
          font-weight: $f-semi-bold;
          font-size: 1.125rem;
          margin-bottom: 0;
        }

        .meta-info {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            font-weight: $f-light;
            font-size: 1rem;

            i {
              color: $green;
              margin-right: 10px;
            }

            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }

      .no-content {
        text-align: center;
        padding: 30px;

        p {
          font-weight: $f-semi-bold;
          margin-bottom: 0;

          font-size: 1.5rem;
          color: $grey;
        }
      }
    }
  }
}

// tenant card
.tenant-card {
  background-color: $white;
  border-top: 5px solid $green;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  padding: 15px 15px 0;

  margin-bottom: 20px;
}

// payment summaries
.date-selector {
  label {
    display: block;
    font-weight: $f-semi-bold;
    margin-bottom: 5px;
  }

  input {
    padding: 10px;
    margin-right: 20px;
    border: 1px solid $green;
  }
}

.summary-table {
  margin-top: 20px;
}
