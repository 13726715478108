.edit-flat-modal {
  .section-heading {
    font-size: 1.25rem;
    font-weight: $f-bold;
  }

  .image-list {
    padding: 0;
    margin: 0;
    border-bottom: 0 !important;
    margin-bottom: 20px !important;

    li {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      .image-wrapper {
        margin-right: 15px;
        position: relative;

        img {
          display: block;
          width: 200px;
        }

        .delete-button {
          border: none;
          padding: 0;
          background-color: transparent;
          position: absolute;
          top: 5px;
          right: 0;

          .fa-circle {
            color: rgba(216, 24, 24, 0.7);
          }

          &:hover {
            .fa-circle {
              color: rgba(216, 24, 24, 1);
            }
          }
        }
      }

      .field {
        width: 100%;
      }

      .move-controls {
        margin-right: 10px;
        align-self: center;

        button {
          background-color: $green;
          color: $purple;
          border: none;
          padding: 10px 15px;

          &:first-child {
            margin-bottom: 10px;
          }

          &:disabled {
            opacity: 0.2;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .modal-content {
    .modal-body {
      .edit-section {
        > :last-child {
          margin-bottom: 0;
        }

        label {
          font-weight: $f-bold;
          display: block;
        }

        .field {
          .error {
            margin-bottom: 0;
            margin-top: 10px;
          }
        }
      }
    }

    .modal-footer {
      justify-content: space-between;
    }

    p.error-message {
      margin-top: 10px;
      color: rgb(216, 24, 24);
      margin-bottom: 0;
    }
  }

  .video-wrapper {
    margin-top: 10px;
    position: relative;

    > div {
      position: relative;
      padding-bottom: 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}

// flat images on page
.flat-images-wrapper {
  margin-bottom: 20px;
}

.ReactModal__Overlay {
  z-index: 10000000 !important;

  .ril__caption {
    justify-content: center;
  }
}

.flat-video-wrapper {
  position: relative;
  max-width: 1000px;
  width: 80%;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);

  .inner {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
