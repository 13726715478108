.property-filter {
  .filter {
    background-color: $purple;
    text-align: center;
    padding: 25px;

    .heading {
      color: $green;
      font-weight: $f-semi-bold;
      font-size: 2rem;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        font-size: 2.5rem;
      }
    }

    .subheading {
      color: $white;
      font-weight: $f-light;
      font-size: 1rem;
      margin-bottom: 30px;

      @media (min-width: 768px) {
        font-size: 1.5rem;
      }
    }

    .criteria {
      text-align: left;

      .field {
        margin-bottom: 10px;

        .slider {
          padding: 0 10px;
        }

        @media (min-width: 768px) {
          padding: 0 10px;
          margin-bottom: 0;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        label {
          color: $green;
          font-weight: $f-semi-bold;
          margin-bottom: 5px;
        }

        select {
          width: 100%;
          color: $purple;
          border: 2px solid $green;
          padding: 5px 5px;
          font-size: 18px;

          &:focus {
            outline: 0;
          }

          option {
            &:first-child {
              font-weight: $f-bold;
            }

            &:hover {
              background-color: $green;
            }
          }
        }

        .input-range {
          margin-top: 17px;

          &__label {
            &--min {
              display: none;
            }

            &--max {
              display: none;
            }

            &-container {
              color: $white;
              font-family: "Open Sans", sans-serif;
            }
          }

          &__track {
            &--active {
              background-color: $green;
            }
          }

          &__slider {
            background: $green;
            border-color: $green;
          }
        }

        &.filter-submit {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-bottom: 0;
          margin-top: 10px;

          .btn.green-button {
            padding: 5px 10px;
            width: 100%;

            @media (min-width: 768px) {
              width: auto;
            }

            i {
              margin-left: 10px;
            }

            &:hover {
              border-color: $green;
              color: $green;
            }
          }
        }
      }
    }
  }
}

// on page filter
.property-filter.page {
  background-image: url("../../img/shape-bg.jpg");
  border-bottom: 10px solid #eeeeee;
  z-index: 1050;

  @media (max-width: 768px) {
    top: 77px;
  }

  .toggle-wrapper {
    padding: 10px 0;

    button {
      color: $purple;
      background-color: transparent;
      border: 0;
      font-size: 20px;
      font-weight: $f-semi-bold;
      transition: all 0.3s ease;

      &:focus {
        outline: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .filter {
    background-color: transparent;
    overflow: hidden;
    height: 100%;
    max-height: 400px;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    padding: 15px 0;

    @media (max-width: 768px) {
      &.hide {
        padding: 0;
        max-height: 0;
      }
    }

    .criteria {
      .field {
        label {
          color: $purple;
        }

        input {
          border-color: $purple;
        }
      }

      .slider {
        margin: 0 5px;
      }

      .input-range {
        margin-top: 17px;

        &__label {
          &-container {
            color: $purple;
            font-family: "Open Sans", sans-serif;
          }
        }

        &__track {
          background-color: $purple;

          &--active {
            background-color: $green;
          }
        }
      }
    }
  }
}
