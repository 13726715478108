.important-notice {
	background: #c50000;
	position: absolute;
	top: 77px;
	width: 100%;
	text-align: center;
	color: #fff;
	padding: 1rem;
	opacity: 0;
	transition: all 300ms ease-in-out;
	@media (min-width: 768px) {
		top: 137px;
	}

	&.show {
		opacity: 1;
	}

	&.hiding {
		opacity: 0;
	}

	&.hidden {
		display: none;
	}

	button {
		border-radius: 0;
		font-size: 1rem !important;
		font-weight: 400 !important;
		padding: 0.375rem 0.75rem !important;
		border: none !important;

		&:focus,
		&:active {
			box-shadow: none !important;
		}

		&:first-child {
			margin-right: 0.5rem;

			&:hover {
				background-color: darken($green, 15%);
			}
		}
	}
}
