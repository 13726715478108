.not-found-page {
  background-color: $light;
  text-align: center;

  .content {
    padding: 30px 0;
    width: 100%;
    max-width: 650px;
    margin: 0 auto;

    .heading {
      color: $green;
      font-size: 6rem;
      font-weight: 700;
    }

    p {
      color: $purple;
      font-size: 1.5rem;
      font-weight: $f-light;
    }
  }
  .property-feed {
    padding-top: 0;
  }
}
