.resources {
  margin-bottom: 20px;

  .heading {
    font-size: 1.25rem;
    font-weight: $f-semi-bold;
  }

  p {
    font-weight: $f-light;
    font-size: 1rem;
  }

  .resource-list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: inline-block;
      width: 50%;
      position: relative;

      @media (min-width: 768px) {
        width: 25%;
      }

      @media (min-width: 992px) {
        width: 20%;
      }

      &.no-content {
        width: 100%;
        background-color: $white;
        text-align: center;
        padding: 30px;
        font-size: 1.5rem;
        color: #707070;
        font-weight: $f-semi-bold;
      }

      .delete-icon {
        position: absolute;
        width: auto;
        right: 0;
        top: -10px;
        transition: all 0.3s ease;

        .fa-stack {
          font-size: 0.8rem;

          .fa-circle {
            color: red;
          }
        }

        &:hover {
          opacity: 0.7;
        }
      }

      button {
        background-color: transparent;
        border: none;
        width: 100%;
        margin-bottom: 20px;

        svg {
          fill: $green;
          transition: all 0.3s ease;
          width: 100%;
          max-width: 100px;
          margin-bottom: 10px;
        }

        p {
          font-size: 0.875rem;
          line-height: 1.2;
          margin-bottom: 0;

          i {
            color: $green;
            margin-right: 5px;
          }

          &.file-name {
            font-weight: $f-semi-bold;
            margin-bottom: 10px;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &:hover {
          svg {
            fill: $purple;
          }
        }

        &:active,
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.upload-resource {
  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }

  .btn {
    background-color: $purple;
    color: $white;
    border-radius: 0;
    font-weight: $f-semi-bold;
    font-size: 18px;
    padding: 10px 15px;
    border: 2px solid $purple;
    transition: all 0.3s ease;

    &:hover {
      background-color: transparent;
      border-color: $purple;
      color: $purple;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .upload-btn-wrapper input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
