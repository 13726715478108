.modal {
  z-index: 12000;
  text-align: left;

  .modal-content {
    border-radius: 0;
    border: 0;
    border-top: 7px solid $green;

    .modal-header {
      .modal-title {
        font-weight: $f-semi-bold;
        font-size: 1.75rem;
        margin-bottom: 0;
      }
    }

    .modal-body {
      .edit-section {
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.25);
          margin-bottom: 15px;
        }

        .heading {
          font-size: 1.5rem;
          font-weight: $f-semi-bold;
          margin-bottom: 5px;
        }

        .field {
          margin-bottom: 20px;
          position: relative;

          .heading {
            font-size: 1.125rem;
            margin-bottom: 5px;
          }

          .description {
            font-weight: $f-light;
            font-size: 0.875rem;
            margin-top: 10px;

            strong {
              font-weight: $f-semi-bold;
            }
          }

          p {
            color: $purple;
          }

          input,
          textarea,
          select {
            padding: 5px;
            width: 100%;
            border: 1px solid $green;

            &:focus {
              outline: none;
              border: 1px solid $purple;
            }
          }

          .query-results {
            position: absolute;
            background: $light;
            width: 100%;
            z-index: 100;

            ul {
              position: relative;
              padding: 0;
              margin: 0;
              list-style: none;

              li {
                button {
                  width: 100%;
                  padding: 10px;
                  text-align: left;
                  background-color: transparent;
                  border: none;
                  cursor: pointer;
                  color: $purple;
                  font-weight: $f-bold;

                  span {
                    opacity: 0.6;
                    font-weight: $f-regular;
                  }
                }

                &:not(:last-child) {
                  border-bottom: 1px solid $grey;
                }
              }
            }
          }

          .selected-user {
            display: flex;
            justify-content: space-between;
            background-color: $light;

            .delete-icon {
              background-color: transparent;
              border: none;

              .fa-circle {
                color: rgba(216, 24, 24, 0.7);
              }
            }
          }

          .react-datepicker {
            &-wrapper {
              width: 100%;
            }

            &__input-container {
              width: 100%;

              input {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
