.login-page {
  padding: 30px 0;
  min-height: 71vh;

  .login-box {
    text-align: center;
    background-color: $purple;
    padding: 50px;

    .header {
      margin-bottom: 30px;

      .heading {
        font-size: 1.5rem;
        color: $green;
        font-weight: $f-semi-bold;
        margin-bottom: 0;
      }

      p {
        margin-top: 30px;
        color: $green;
        font-weight: $f-light;
      }
    }

    .content {
      .field-wrapper {
        margin-bottom: 20px;

        .text-danger {
          margin-top: 5px;
        }

        .field {
          background-color: $light;
          display: flex;
          align-items: center;
          margin: 0 auto;
          padding: 5px 10px;
          border: 2px solid $green;
          width: 100%;
          text-align: left;

          i {
            color: $green;
            margin-right: 10px;
          }

          input {
            border: none;
            background-color: $light;
            color: $purple;
            width: 100%;

            &:focus {
              outline: none;
            }
          }
        }
      }

      .btn.green-button {
        width: 100%;

        &:hover {
          border-color: $green;
          color: $green;
        }
      }
    }
  }
}

.footer-box {
  text-align: center;
  padding: 50px;
  background-color: $light-purple;
  color: $white;

  p {
    font-weight: $f-light;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $white;
    font-weight: $f-semi-bold;
  }
}
