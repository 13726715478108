.blog-feed {
  background-color: $light;
  padding-bottom: 30px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .heading {
      font-weight: $f-semi-bold;
      font-size: 1.125rem;
      flex-shrink: 0;
      margin-bottom: 0;
      margin-right: 10px;
    }

    hr {
      border-top: 1px solid $green;
      width: 100%;
      margin: 0;
    }
  }

  .row {
    margin-right: -7px;
    margin-left: -7px;

    [class*='col-'] {
      padding-right: 7px;
      padding-left: 7px;
    }
  }

  .blog-post {
    height: 100%;
    flex-direction: column;
    padding-bottom: 20px;

    a {
      display: block;
      height: 100%;
      transition: all 0.3s ease;
      text-decoration: none;

      .post-box {
        &:hover {
          transform: scale(1.02);
          box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);

          .thumbnail {
            opacity: 0.7;
          }
        }
      }
    }

    .post-box {
      display: flex;
      height: 100%;
      background-color: $white;
      flex-direction: column;
      transition: all 0.3s ease;

      .thumbnail-wrapper {
        width: 100%;
        height: 100%;
        min-height: 200px;
        flex-grow: 1;

        .thumbnail {
          background-position: center center;
          background-size: cover;
          height: 100%;
          width: 100%;
          transition: all 0.3s ease;
        }
      }

      .content {
        justify-self: flex-end;
        padding: 20px;
        flex-grow: 0;

        .heading {
          font-size: 1.125rem;
          font-weight: $f-semi-bold;
          margin-bottom: 10px;
          color: $purple;
        }

        .description {
          p {
            font-size: 0.875rem;
            font-weight: $f-light;
            margin-bottom: 0;
            color: $purple;
          }
        }
      }
    }
  }
}
