.property-page {
  padding: 20px 0;
  background-color: $light;

  @media (min-width: 768px) {
    padding: 50px 0;
  }

  .property-header {
    margin-bottom: 0px;

    .main-meta {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 10px;

      .save-wrapper {
        button {
          background: transparent;
          border: 0;
          padding: 0;
          margin: 0;
          display: block;
          transition: all 0.3s ease;

          i {
            font-size: 1.25rem;
            display: block;
          }

          &:hover {
            transform: scale(1.2);
          }

          &:focus {
            outline: 0;
          }
        }
      }

      .heading,
      .price {
        font-size: 1.125rem;
        font-weight: $f-semi-bold;
      }

      .heading {
        margin-bottom: 0;
      }
    }

    .address {
      font-weight: $f-light;
      font-size: 1rem;
      margin-bottom: 0;
    }
  }

  .main-content {
    .gallery {
      margin-top: 20px;
      border-top: 7px solid $green;
      margin-bottom: 4px;

      @media (min-width: 768px) {
        margin-top: 30px;
      }

      .main-slider {
        position: relative;
        margin-bottom: 20px;

        @media (min-width: 768px) {
          margin-bottom: 4px;
        }

        .slider-navigation {
          width: 100%;
          background-color: $purple;
          text-align: center;
          padding: 7px 0;
          margin-top: -6px;

          @media (min-width: 768px) {
            position: absolute;
            bottom: 6px;
            left: 0;
            z-index: 100;
            background-color: rgba(12, 10, 45, 0.67);
          }

          .inner-nav {
            display: inline-flex;
            align-items: center;
            color: $white;

            p {
              margin-bottom: 0;
              font-weight: $f-light;
              margin: 0 15px;
            }

            .prev-arrow,
            .next-arrow {
              background-color: transparent;
              border: 0;
            }

            .next-arrow {
              img {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      .nav-slider {
        margin-left: -5px;
        margin-right: -5px;
        display: none;

        @media (min-width: 768px) {
          display: block;
        }

        .slick-slide {
          max-width: 200px;
        }

        .slide {
          width: 100%;

          .bg-image {
            margin: 0 5px;
            padding-bottom: 70%;
            background-size: cover;
            background-position: center center;
            cursor: pointer;
          }
        }

        .slick-current {
          img,
          .bg-image {
            padding-bottom: 67%;
            border: 3px solid $purple;
          }
        }
      }

      img {
        width: 100%;
        max-height: 860px;
        object-fit: contain;
        object-position: center;
      }
    }

    .cta-request {
      background-color: $purple;
      padding: 20px;
      text-align: center;
      margin-bottom: 20px;

      @media (min-width: 568px) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: left;
      }

      p {
        color: $white;
        margin-bottom: 20px;
        font-size: 1.125rem;
        font-weight: $f-light;

        @media (min-width: 568px) {
          margin-bottom: 0;
        }

        a {
          color: $white;
          font-weight: $f-bold;
          text-decoration: none;
          transition: all 0.3s ease;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .green-button {
        &:hover {
          border-color: $green;
          color: $green;
        }
      }
    }
  }

  // right sidebar
  .right-sidebar {
    display: none;
    padding-top: 30px;

    @media (min-width: 768px) {
      display: block;
    }

    .sidebar-section {
      padding: 20px;
      text-align: center;
      background-color: $white;

      &:first-child {
        border-top: 7px solid $purple;
      }

      &:not(:last-child) {
        border-bottom: 1px dashed $purple;
      }

      .heading {
        font-size: 1.125rem;
        font-weight: $f-semi-bold;
      }

      p {
        font-weight: $f-light;
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }

      &.cta {
        .btn {
          margin-bottom: 20px;
        }

        .call {
          font-size: 18px;
          font-weight: $f-light;
          margin-bottom: 0;

          a {
            color: $purple;
            font-weight: $f-bold;
            text-decoration: none;
            transition: all 0.3s ease;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }

      &.description {
        text-align: left;

        p {
          font-size: 0.875rem;
        }

        a {
          color: $purple;
          font-size: 0.875rem;
          font-weight: $f-semi-bold;
          text-decoration: none;
          transition: all 0.3s ease;

          span {
            transition: all 0.3s ease;
            display: inline-block;
          }

          &:hover {
            span {
              transform: translateX(3px);
            }
          }
        }
      }

      &.button {
        padding: 0;

        .full {
          display: block;
          text-decoration: none;
          width: 100%;
          padding: 30px;
          background-color: transparent;
          border: none;
          font-weight: $f-semi-bold;
          color: $purple;
          transition: all 0.3s ease;

          &:hover {
            background: #e6e6e6;
          }

          &:active,
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  // property info
  .property-info {
    .nav-tabs {
      border: 0;

      .nav-item {
        &:first-child {
          margin-right: 20px;
        }

        .nav-link {
          font-size: 1.125rem;
          color: $purple;
          font-weight: $f-semi-bold;
          border-radius: 0;
          border: 0;
          padding: 10px 15px;
          box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);

          @media (min-width: 768px) {
            padding: 20px 30px;
          }

          &.active {
            background-color: $white;
            box-shadow: none;
          }
        }
      }
    }

    .tab-content {
      position: relative;
      background-color: $white;
      z-index: 100;
      padding: 15px;

      @media (min-width: 768px) {
        padding: 30px;
      }

      .heading {
        font-size: 1.125rem;
        font-weight: $f-semi-bold;
        color: $purple;
      }

      p {
        margin-bottom: 0;
        font-size: 0.875rem;
        font-weight: $f-light;
        white-space: pre-line;
      }

      .in-the-area {
        margin-bottom: 30px;

        @media (min-width: 768px) {
          margin-bottom: 50px;
        }

        .stats {
          .stat {
            padding: 10px;
            display: flex;
            align-items: center;

            .img-wrapper {
              flex-shrink: 0;
              position: relative;
              margin-right: 15px;

              img {
                width: 100%;
                height: 100%;
                max-width: 40px;

                @media (min-width: 568px) {
                  max-width: 50px;
                }
              }

              // &:before {
              //   content: '';
              //   position: absolute;
              //   width: 50px;
              //   height: 50px;
              //   background-color: $green;
              //   z-index: -1;
              //   transform: rotate(-45deg);
              // }
            }
          }
        }
      }

      .key-features {
        margin-bottom: 30px;

        @media (min-width: 768px) {
          margin-bottom: 50px;
        }

        .feature {
          border-bottom: 1px dashed $purple;
          display: flex;
          align-items: center;
          padding: 20px 10px;

          .diamond-icon {
            position: relative;
            width: 20px;
            height: 20px;
            margin-right: 15px;

            &:before {
              content: "";
              top: 0;
              left: 0;
              position: absolute;
              width: 18px;
              height: 18px;
              background-color: $green;
              transform: rotate(45deg);
            }
          }
        }
      }

      .description-wrapper {
        margin-bottom: 30px;

        @media (min-width: 768px) {
          margin-bottom: 50px;
        }

        p {
          white-space: pre-wrap;
        }
      }
    }
  }
}

.flat-list {
  .card {
    border-radius: 0;
    margin-bottom: 10px;

    .card-header {
      background-color: $white;
      border-radius: 0;
      border-top: 7px solid $purple;
      padding: 0;

      button {
        text-decoration: none;
        color: $purple;
        padding: 10px;
        background-color: transparent;
        border: 0;
        font-size: 1.125rem;
        width: 100%;
        text-align: left;
        font-weight: $f-semi-bold;

        span {
          font-weight: $f-light;
          text-transform: uppercase;
          font-size: 0.875rem;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .card-body {
      padding: 0;

      @media (min-width: 768px) {
        padding: 1.25rem;
      }

      table {
        width: 100%;
        font-size: 0.75rem;
        margin-bottom: 0;

        @media (min-width: 568px) {
          font-size: 0.875rem;
        }

        @media (min-width: 768px) {
          font-size: 1rem;
        }

        tr {
          &:nth-child(even) {
            background-color: $light;
          }
        }

        th {
          text-transform: uppercase;
          font-weight: $f-semi-bold;
          font-size: 0.8rem;
          padding: 5px;
          border-top-color: transparent;
        }

        td {
          font-weight: $f-light;
          vertical-align: middle;

          i.fa-check {
            color: $green;
          }

          &:first-child {
            white-space: nowrap;

            @media (min-width: 568px) {
              padding-left: 20px;
            }
          }

          &:not(:first-child) {
            padding: 5px;
            @media (min-width: 568px) {
              padding: 10px 20px;
            }
          }

          &.no-wrap {
            white-space: nowrap;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// enquiry modal
.enquiry-modal {
  .modal-body {
    .field {
      margin-bottom: 10px;

      label {
        color: $purple;
        font-weight: $f-bold;
        display: block;
        margin-bottom: 5px;
      }

      input,
      textarea,
      select {
        width: 100%;
        border: 1px solid $green;
        padding: 10px;

        &:focus {
          outline: none;
          border: 1px solid $purple;
        }

        &.field-error {
          border: 1px solid red;
        }
      }

      p.error {
        color: red;
        font-size: 0.875rem;
      }

      p {
        color: $purple;
      }
    }

    .confirmation {
      padding: 30px 0;
      text-align: center;
      .heading {
        font-weight: $f-semi-bold;
        font-size: 1.5rem;
      }

      p {
        color: $purple;
        font-weight: $f-light;
        margin-bottom: 0;
      }
    }
  }
}
