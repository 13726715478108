// base
@import "base/colors";
@import "base/fonts";

// layout
@import "layout/header";
@import "layout/footer";

// elements
@import "elements/property-filter";
@import "elements/modal";
@import "elements/blog-feed";
@import "elements/important-notice";
@import "elements/flat-images";

// pages
@import "pages/home";
@import "pages/login";
@import "pages/admin-panel";
@import "pages/property";
@import "pages/properties";
@import "pages/user-panel";
@import "pages/resources";
@import "pages/content";
@import "pages/contact";
@import "pages/saved-properties";
@import "pages/not-found";

// global styles
body {
  font-family: "Open Sans", sans-serif;
  color: $purple;
  overflow-x: hidden;
}

::-moz-selection {
  background: $green;
}
::selection {
  background: $green;
}

.btn.green-button {
  background-color: $green;
  color: $purple;
  border-radius: 0;
  font-weight: $f-semi-bold;
  font-size: 18px;
  padding: 10px 15px;
  border: 2px solid $green;
  transition: all 0.3s ease;

  &:hover {
    background-color: transparent;
    border-color: $purple;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn.purple-button {
  background-color: $purple;
  color: $white;
  border-radius: 0;
  font-weight: $f-semi-bold;
  font-size: 18px;
  padding: 10px 15px;
  border: 2px solid $purple;
  transition: all 0.3s ease;

  &:hover {
    background-color: transparent;
    border-color: $purple;
    color: $purple;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn.red-button {
  background-color: #ec334b;
  color: $white;
  border-radius: 0;
  font-weight: $f-semi-bold;
  font-size: 18px;
  padding: 10px 15px;
  border: 2px solid #ec334b;
  transition: all 0.3s ease;

  &:hover {
    background-color: transparent;
    border-color: #ec334b;
    color: #ec334b;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.tooltip-inner {
  color: $green;
  background-color: $purple;
}

.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  border-top-color: $purple;
}

// default pill styling
.badge.badge-pill {
  background-color: #cce2da;
  color: #1a5742;
  font-size: 0.875rem;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
}

// row changes
.row.narrow {
  margin-left: -10px;
  margin-right: -10px;

  > [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
}
