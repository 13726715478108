// wrapper styling
.homepage {
  background-color: $light;

  .property-filter {
    margin-top: 60px;
  }
}

// home hero
.hero {
  background-image: url("../../img/shape-bg.jpg");
  background-size: cover;
  background-position: center center;
  padding-top: 30px;
  border-bottom: 10px solid #eeeeee;

  img {
    width: 100%;
    margin-bottom: -40px;
  }
}

.featured-properties {
  padding: 30px 0;

  .row {
    margin-right: -7px;
    margin-left: -7px;

    [class*="col-"] {
      padding-right: 7px;
      padding-left: 7px;
    }
  }

  .properties {
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .heading {
        font-weight: $f-semi-bold;
        font-size: 1.125rem;
        flex-shrink: 0;
        margin-bottom: 0;
        margin-right: 10px;
      }

      hr {
        border-top: 1px solid $green;
        width: 100%;
        margin: 0;
      }
    }

    .col-sm-6 {
      margin-bottom: 15px;

      @media (min-width: 1200px) {
        margin-bottom: 0;
      }
    }

    .property-link {
      text-decoration: none;
      transition: all 0.3s ease;
      display: block;

      &:hover {
        transform: scale(1.02);

        .property {
          box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
        }

        .featured-image {
          opacity: 0.7;
        }
      }

      .property {
        background-color: $white;
        color: $purple;
        transition: all 0.3s ease;

        .featured-image {
          padding-bottom: 100%;
          background-size: cover;
          background-position: center center;
          transition: all 0.3s ease;
        }

        .property-info {
          padding: 15px;

          .meta-header {
            margin-bottom: 20px;
            .name {
              font-size: 1.125rem;
              font-weight: $f-semi-bold;
            }

            hr {
              border-top: 1px solid $grey;
              margin: 0;
            }

            .price {
              margin-top: 0.5rem;
              font-size: 1rem;
              font-weight: $f-light;
              margin-bottom: 0;
            }
          }

          .meta-data {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
              font-weight: $f-light;
              font-size: 0.875rem;
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }

              i {
                color: $green;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .cta {
      height: 100%;
      background-color: $white;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .heading {
        font-size: 1.125rem;
        font-weight: $f-semi-bold;
      }

      p {
        font-weight: $f-light;
        font-size: 0.875rem;
      }

      .call-us {
        font-size: 1.125rem;

        a {
          color: $purple;
          font-weight: $f-bold;
        }
      }
    }
  }

  .right-sidebar {
    height: 100%;
    background-color: $white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .heading {
      font-size: 1.125rem;
      font-weight: $f-semi-bold;
      margin-bottom: 1rem;
    }

    p {
      font-weight: $f-light;
      font-size: 0.875rem;
    }

    .call-us {
      font-size: 1.125rem;
      margin-bottom: 1rem;

      a {
        color: $purple;
        font-weight: $f-bold;
      }
    }
  }
}

// blog styling
.our-blog {
  padding-bottom: 50px;

  .row {
    margin-right: -7px;
    margin-left: -7px;

    [class*="col-"] {
      padding-right: 7px;
      padding-left: 7px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .heading {
      font-weight: $f-semi-bold;
      font-size: 1.125rem;
      flex-shrink: 0;
      margin-bottom: 0;
      margin-right: 10px;
    }

    hr {
      border-top: 1px solid $green;
      width: 100%;
      margin: 0;
    }
  }

  .blog-post {
    margin-bottom: 15px;

    @media (min-width: 992px) {
      margin-bottom: 0;
    }

    .post-content {
      background-color: $white;
      height: 100%;

      .featured-image {
        a {
          transition: all 0.3s ease;

          img {
            width: 100%;
          }

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .post-info {
        padding: 15px;

        .title {
          text-decoration: none;
          transition: all 0.3s ease;

          h3 {
            font-size: 1.125rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $purple;
            min-height: 45px;
            font-weight: $f-semi-bold;
            transition: all 0.3s ease;
          }

          &:hover {
            h3 {
              opacity: 0.7;
            }
          }
        }

        .excerpt {
          font-weight: $f-light;
          font-size: 0.875rem;
        }

        .read-more {
          color: $purple;
          font-weight: $f-semi-bold;
          text-decoration: none;
          transition: all 0.3s ease;

          span {
            display: inline-block;
            transition: all 0.3s ease;
          }

          &:hover {
            opacity: 0.7;

            span {
              transform: translateX(3px);
            }
          }
        }
      }
    }
  }
}
