.contact-page {
  background-color: $light;

  .main-content {
    margin-top: 40px;
    padding: 30px 0;

    @media (min-width: 768px) {
      padding: 50px 0;
    }

    .contact-form {
      .field {
        margin-bottom: 10px;

        label {
          display: block;
          font-weight: $f-bold;
          color: $purple;
          font-size: 1.125rem;
          margin-bottom: 5px;
        }

        input,
        textarea,
        select {
          width: 100%;
          border: 1px solid $green;
          padding: 10px;

          &:focus {
            outline: none;
            border-color: $purple;
          }
        }

        .error {
          color: red;
          font-size: 0.875rem;
          font-weight: $f-light;
        }
      }

      .success {
        margin-top: 30px;

        .heading {
          color: $purple;
          font-weight: $f-semi-bold;

          p {
            font-weight: $f-light;
          }
        }
      }
    }

    .contact-info {
      background-color: $purple;
      color: white;
      padding: 50px;
      margin-bottom: 30px;

      @media (min-width: 768px) {
        margin-top: 30px;
        margin-bottom: 0;
      }

      @media (min-width: 992px) {
        padding: 50px;
      }

      .info-section {
        position: relative;

        &:not(:last-child) {
          margin-bottom: 30px;
        }

        .heading {
          margin-bottom: 0;
          font-weight: $f-bold;
          font-size: 1.125rem;
        }

        p,
        a {
          display: block;
          width: 100%;
          color: $green;
          font-weight: $f-light;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .map-wrapper {
    width: 100%;

    iframe {
      height: 300px;
      width: 100%;
      display: block;
      border: none;
      border-top: 5px solid $green;
    }
  }
}
