.user-panel {
  padding: 20px 0;

  @media (min-width: 768px) {
    padding: 50px 0;
  }

  .user-sidebar {
    background-color: $purple;
    color: $green;
    padding: 20px;
    margin-bottom: 20px;

    .heading {
      font-weight: $f-semi-bold;
      font-size: 1.25rem;
      color: $white;
    }

    .panel-links {
      list-style: none;
      padding: 0;
      margin: 0;

      .panel-item {
        .panel-link {
          color: $green;
          text-decoration: none;
          transition: all 0.3s ease;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }

  .panel-contents {
    padding: 20px;
    background-color: $light;
    color: $purple;

    .header {
      margin-bottom: 20px;

      .heading {
        font-weight: $f-semi-bold;
        font-size: 1.5rem;
        margin-bottom: 0;
      }
    }

    .tenancy-section {
      .header {
        margin-bottom: 10px;

        .heading {
          font-size: 1.25rem;
          margin-bottom: 0;
        }
      }

      .room-card {
        background-color: $white;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .header {
          background-color: $green;
          padding: 10px;
          margin-bottom: 0;

          .heading {
            font-size: 1.125rem;
            font-weight: $f-semi-bold;
            color: $purple;
            margin-bottom: 0;
          }
        }

        .left-content {
          .featured-images {
            display: flex;

            .image-block {
              width: 100%;
              padding-bottom: 40%;
              background-size: cover;
              background-position: center center;

              &:first-child {
                margin-right: 1.5px;
              }

              &:last-child {
                margin-left: 1.5px;
              }
            }
          }

          .address {
            padding: 20px 20px 0;

            @media (min-width: 768px) {
              padding: 20px;
            }

            h3 {
              font-size: 1.125rem;
              margin-bottom: 0;
              font-weight: $f-semi-bold;
              color: $purple;
            }
          }
        }

        .property-info {
          color: $purple;
          padding: 20px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .address {
            font-weight: $f-semi-bold;
            font-size: 1.125rem;
            margin-bottom: 0;
          }

          .meta-info {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              font-weight: $f-light;
              font-size: 0.875rem;

              i {
                color: $green;
                margin-right: 10px;
              }

              &:not(:last-child) {
                margin-bottom: 10px;
              }
            }
          }

          .description {
            p {
              font-size: 0.875rem;
              margin-bottom: 0;
              font-weight: $f-light;
            }
          }
        }

        .no-content {
          text-align: center;
          padding: 30px;

          p {
            font-weight: $f-semi-bold;
            margin-bottom: 0;

            font-size: 1.5rem;
            color: $grey;
          }
        }
      }
    }
  }

  form {
    .field {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      label {
        display: block;
        font-weight: $f-semi-bold;
      }

      select {
        width: 100%;
        max-width: 600px;
        padding: 10px;
        border-color: $purple;

        &:focus {
          outline: none;
          border-color: $green;
        }
      }

      textarea,
      input {
        display: block;
        width: 100%;
        max-width: 600px;
        border: 1px solid $purple;
        padding: 10px;

        &:focus {
          border-color: $green;
          outline: none;
        }
      }
    }
  }
}

// payments page
.user-payments {
  .payments-section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .heading {
      font-size: 1.25rem;
      font-weight: $f-semi-bold;
      margin-bottom: 15px;
    }

    .payment-wrapper {
      &:last-child {
        .payment-card {
          margin-bottom: 0;
        }
      }

      .payment-card {
        display: block;
        padding: 20px;
        background-color: $purple;
        color: $white;
        border: none;
        border-top: 5px solid $green;
        transition: all 0.3s ease;
        margin-bottom: 20px;
        width: 100%;
        text-align: left;
        opacity: 0.8;

        &.button-hover {
          opacity: 1;
        }

        .pc-header {
          @media (min-width: 768px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .heading {
            color: $green;
            order: 1;
          }

          .amount-card {
            background-color: $green;
            padding: 10px;
            display: inline-flex;
            order: 2;
            margin-bottom: 16px;

            @media (min-width: 768px) {
              margin-bottom: 0;
            }

            .amount {
              font-weight: $f-semi-bold;
              color: $purple;
              margin-bottom: 0;
            }
          }
        }

        .attribute {
          &:not(:last-child) {
            margin-bottom: 10px;
          }

          .heading {
            margin-bottom: 0;
            font-size: 0.9rem;
            text-transform: uppercase;
          }

          p {
            margin-bottom: 0;
          }
        }

        .footer {
          background-color: $light-purple;
          margin-right: -20px;
          margin-left: -20px;
          margin-bottom: -20px;
          padding: 30px;
          text-align: center;

          h5 {
            margin-bottom: 0;
            font-weight: $f-semi-bold;
          }
        }

        &.button-hover:hover {
          box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
          transform: scale(1.02);
          text-decoration: none;
        }
      }
    }

    .show-button {
      width: 100%;
      padding: 20px;
      background-color: $purple;
      border: none;
      border-top: 5px solid $green;
      color: $white;
      opacity: 0.8;
      font-weight: $f-semi-bold;
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.02);
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
      }
    }
  }

  .no-content {
    background-color: $white;
    padding: 30px;
    text-align: center;

    p {
      font-weight: $f-semi-bold;
      margin-bottom: 0;
    }
  }
}

// payment page
.pay-invoice {
  .invoice-information {
    .info-section {
      .heading {
        font-weight: $f-semi-bold;
        margin-bottom: 0;
        font-size: 0.8rem;
        text-transform: uppercase;
      }
    }
  }

  .personal-info {
    margin-bottom: 20px;
  }

  .payment-details {
    .subheading {
      font-weight: $f-semi-bold;
      font-size: 1.25rem;
    }

    #sp-container {
      max-width: 700px;
      min-width: 280px;
      margin-left: 0;
      margin-bottom: 20px;
    }

    .submit-payment {
      @media (min-width: 568px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .button-wrapper {
        .loading {
          min-width: 178px;
        }
      }

      .processed-by {
        @media (max-width: 568px) {
          margin-top: 30px;
        }

        p {
          margin-bottom: 5px;
        }

        img {
          width: 100%;
          max-width: 150px;
        }
      }
    }
  }
}
