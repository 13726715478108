footer {
  border-top: 2px solid $green;
  background-color: $purple;
  color: $white;
  padding: 20px 0;

  .inner-content {
    text-align: center;

    @media (min-width: 568px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .logo {
      margin-bottom: 20px;

      @media (min-width: 568px) {
        margin-bottom: 0;
      }

      img {
        width: 100%;
        max-width: 200px;
      }
    }

    .social {
      text-align: center;

      .icons {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;

        li {
          display: inline-block;
          font-size: 2.5rem;
          line-height: 1;

          a {
            color: $white;
            text-decoration: none;
            margin-right: 15px;
            transition: all 0.3s ease;
            display: block;

            &:hover {
              opacity: 0.7;
              transform: translateY(-3px);
            }
          }

          &:last-child {
            a {
              margin-right: 0;
            }
          }
        }
      }

      .phone {
        font-size: 1.125rem;
        font-weight: $f-light;
        margin-bottom: 15px;

        a {
          color: $white;
          font-weight: $f-bold;
          transition: all 0.3s ease;
          text-decoration: none;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .btn.green-button {
        &:hover {
          border-color: $green;
          color: $green;
        }
      }
    }
  }
}

.small-footer {
  background-color: $light-purple;
  padding: 7px 0;

  .content {
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
      display: flex;
      justify-content: space-between;
    }

    .links {
      margin-bottom: 5px;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          list-style: none;

          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }

    a {
      color: $white;
      font-weight: $f-semi-bold;
      text-decoration: none;

      &:hover {
        opacity: 0.7;
        transition: all 0.3s ease;
      }
    }

    p {
      margin-bottom: 0;
      color: $white;
    }
  }
}
