.property-feed {
  background-color: $light;
  padding: 20px 0;

  .row {
    margin-right: -10px;
    margin-left: -10px;

    [class*='col-'] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .properties {
    text-align: left;

    &.no-content-feed {
      margin-top: 20px;

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .heading {
          font-weight: $f-semi-bold;
          font-size: 1.125rem;
          flex-shrink: 0;
          margin-bottom: 0;
          margin-right: 10px;
        }

        hr {
          border-top: 1px solid $green;
          width: 100%;
          margin: 0;
        }
      }
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .no-content {
    background-color: $purple;
    color: $white;
    text-align: center;
    padding: 30px;

    .heading {
      margin-bottom: 0;
      font-weight: $f-semi-bold;
    }

    .green-button {
      margin-top: 30px;

      &:hover {
        border-color: $green;
        color: $green;
      }
    }
  }

  .properties-sidebar {
    padding-top: 130px;
    margin-top: -130px;
  }
}

.property-card {
  background-color: $white;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .header {
    background-color: $green;
    padding: 10px;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .heading {
      font-size: 1.125rem;
      font-weight: $f-semi-bold;
      color: $purple;
      margin-bottom: 0;
    }

    .save-wrapper {
      button {
        background-color: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        color: $purple;
        display: block;

        i {
          font-size: 1.5rem;
          display: block;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .left-content {
    .featured-images {
      display: flex;

      .image-block {
        width: 100%;
        padding-bottom: 40%;
        background-size: cover;
        background-position: center center;

        &:nth-child(odd) {
          margin-right: 1.5px;
        }

        &:nth-child(even) {
          margin-left: 1.5px;
        }
      }
    }

    .price {
      padding: 20px 20px 0;

      // padding: 0px 0px 20px 25px;
      @media (min-width: 768px) {
        padding: 25px;
      }

      h3 {
        font-size: 1rem;
        font-weight: $f-semi-bold;
        color: $purple;
        margin-bottom: 1rem;

        @media (min-width: 768px) {
          font-size: 1.125rem;
          margin-bottom: 0;
        }
      }
    }
  }

  .property-info {
    color: $purple;
    padding: 0 20px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;

    @media (min-width: 768px) {
      padding: 25px;
    }

    .address {
      font-size: 1rem;
      margin-bottom: 1rem;

      @media (min-width: 768px) {
        font-weight: $f-semi-bold;
        font-size: 1.125rem;
      }

      .no-break {
        white-space: nowrap;
      }
    }

    .meta-info {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 1rem;

      li {
        font-weight: $f-light;
        font-size: 0.875rem;

        i {
          color: $green;
          margin-right: 10px;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    .description {
      p {
        font-size: 0.875rem;
        margin-bottom: 0;
        font-weight: $f-light;
      }
    }
  }
}

// properties sidebar
.properties-sidebar {
  padding-top: 20px;
  margin-top: -20px;

  .inner-content {
    background-color: $white;

    .header {
      background-color: $purple;
      color: $green;
      padding: 10px;

      .heading {
        font-weight: $f-semi-bold;
        font-size: 1.125rem;
        margin-bottom: 0;
      }
    }

    .content {
      padding: 20px;

      p {
        font-size: 0.875rem;
        font-weight: $f-light;
      }

      .call-us {
        font-weight: $f-light;
        font-size: 1.125rem;
        margin-bottom: 1rem;

        a {
          font-weight: $f-semi-bold;
          color: $purple;
        }
      }

      .btn {
        width: 100%;
      }
    }
  }
}
