// header styling
header {
  z-index: 9000 !important;

  .nav-wrapper {
    padding: 10px 0;
    border-bottom: 2px solid $green;
    background-color: $purple;

    @media (min-width: 768px) {
      padding: 25px;
    }

    .logo {
      z-index: 9000;

      img {
        max-width: 200px;

        @media (min-width: 768px) {
          max-width: 100%;
        }
      }
    }

    .navigation {
      .navbar {
        padding: 0;

        .navbar-nav {
          .nav-item {
            .nav-link {
              color: $white;
              font-weight: $f-light;
              font-size: 1.25rem;
              transition: all 0.3s ease;
              padding-right: 1rem;
              padding-left: 1rem;

              i {
                color: $green;
                margin-left: 5px;
              }

              &:hover {
                opacity: 0.7;
              }
            }

            &.dropdown {
              .dropdown-menu {
                border: 1px solid $green;
                background-color: $purple;
                border-radius: 0;
                z-index: 2000;

                .dropdown-item {
                  color: $white;

                  &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                    color: $green;
                  }
                }

                .dropdown-divider {
                  border-top: 1px solid $green;
                }
              }
            }

            &:first-child {
              .nav-link {
                margin-left: 0;
              }
            }

            &:last-child {
              .nav-link {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }

  .toggle-wrapper {
    z-index: 9000;

    .hamburger {
      padding: 15px 0 10px;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      .hamburger-box {
        .hamburger-inner {
          background-color: $green;

          &:before,
          &:after {
            background-color: $green;
          }
        }
      }
    }
  }
}

.mobile-menu {
  background-color: $purple;
  position: fixed;
  z-index: 5000;
  overflow-y: hidden;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  max-height: 100%; /* approximate max height */
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: linear;

  &.closed {
    max-height: 0;
  }

  .mobile-nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .navbar-nav {
      display: inline-block;
      margin-left: 0 !important;

      .nav-item {
        .nav-link {
          color: $white;
          background-color: transparent;
          border: none;
          font-size: 1.25rem;

          .badge {
            margin-left: 5px;
          }
        }
      }

      .nav-divider {
        margin: 10px 0;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
  }
}

// user bar
.user-bar {
  background-color: $light-purple;
  color: $white;
  padding: 7px 0;

  .inner-content {
    display: flex;

    .user-nav-links {
      margin: 0;
      padding: 0;
      list-style: none;

      .user-nav-link {
        display: inline-block;
        margin: 0 10px;

        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          margin-left: 0;
        }

        i {
          color: $white;
        }

        a,
        button {
          background-color: transparent;
          border: none;
          padding: 0;
          margin: 0;
          color: $white;
          text-decoration: none;
          transition: all 0.3s ease;
          font-size: 1rem;

          &:hover {
            opacity: 0.7;
          }

          &:focus {
            outline: none;
          }
        }

        &.saved-link {
          a {
            display: flex;
            align-items: center;

            i {
              margin-right: 5px;

              &.green {
                color: $green;
              }
            }
          }
        }
      }
    }
  }
}
