.saved-properties {
  padding: 30px 0;
  background-color: $light;

  @media (min-width: 768px) {
    padding: 50px 0;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .heading {
      font-weight: $f-semi-bold;
      font-size: 1.125rem;
      flex-shrink: 0;
      margin-bottom: 0;
      margin-right: 10px;
    }

    hr {
      border-top: 1px solid $green;
      width: 100%;
      margin: 0;
    }
  }

  .property-card-wrapper {
    position: relative;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
    }

    .property-card {
      &:hover {
        box-shadow: none;
        transform: none;
      }
    }

    .delete-property {
      position: absolute;
      top: 6px;
      right: 2px;
      background-color: transparent;
      padding: 0;
      margin: 0;
      border: none;
      transition: all 0.3s ease;

      .fa-stack {
        font-size: 15px;

        .fa-circle {
          color: $purple;
        }
      }

      &:hover {
        opacity: 0.7;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .no-content {
    background-color: $purple;
    color: $white;
    text-align: center;
    padding: 30px;
    margin-bottom: 20px;

    .heading {
      margin-bottom: 0;
      font-weight: $f-semi-bold;
    }

    .green-button {
      margin-top: 30px;

      &:hover {
        border-color: $green;
        color: $green;
      }
    }
  }
}
