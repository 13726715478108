// content page styling
.content-page {
  background-color: $light;

  .row {
    margin-left: -10px;
    margin-right: -10px;

    [class*='col-'] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .main-content {
    margin-top: 40px;
    padding: 30px 0 10px;

    @media (min-width: 768px) {
      padding: 50px 0 30px;
    }

    .header {
      overflow: hidden;
      margin-bottom: 10px;

      .heading {
        font-size: 1.125rem;
        font-weight: $f-semi-bold;
        color: $purple;
        position: relative;
        display: inline-block;

        &:after {
          content: '';
          position: absolute;
          width: 100vw;
          height: 1px;
          left: 100%;
          top: 50%;
          margin-left: 10px;
          border-top: 1px solid $green;
        }
      }
    }

    .text-content {
      background-color: $white;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
      margin-bottom: 20px;
      padding: 15px;

      @media (min-width: 568px) {
        padding: 30px;
      }

      @media (min-width: 768px) {
        padding: 50px;
      }

      @media (min-width: 992px) {
        padding: 70px;
      }

      p {
        font-weight: $f-light;
        font-size: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        font-weight: $f-semi-bold;
        color: $purple;
      }

      .subheading {
        font-size: 1.125rem;
        color: $purple;
        font-weight: $f-semi-bold;
        margin-bottom: 20px;
      }

      hr {
        border-top: 1px dotted $purple;
        margin: 30px 0;
      }
    }

    .images-container {
      width: 100%;

      img {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
}
